import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutFull from '@/layouts/full/LayoutFull'
import Auth from '@/services/auth.service.js'
import userPages from './user.pages'
import adminPages from './admin.pages'
import store from '@/store/index.js'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '',
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [// ---------------------
    // Dashboard Admin Pages
    // ---------------------
    ...adminPages, // --------------------
    // Dashboard User Pages
    // --------------------
    ...userPages, // --------------
    // Authentication
    // --------------
    {
      path: '/',
      redirect: '/auth/login'
    }, {
      path: '/auth',
      component: LayoutFull,
      children: [{
        path: 'login',
        name: 'login',
        component: () => import('@/views/auth/Login.vue'),
        meta: {
          layout: 'full',
          auth: 'guest'
        },
      }, {
        path: 'register',
        name: 'register',
        component: () => import('@/views/auth/Register.vue'),
        meta: {
          layout: 'full',
          auth: 'guest'
        },
      }, {
        path: 'forget-pass',
        name: 'forget-pass',
        component: () => import('@/views/auth/ForgetPassword.vue'),
        meta: {
          layout: 'full',
          auth: 'guest'
        },
      }, {
        path: ':provider/callback',
        component: () => import('@/views/auth/ProviderCallback.vue'),
        meta: {
          layout: 'full',
          auth: 'guest'
        },
      }, {
        path: 'reset-pass/:token',
        name: 'reset-pass',
        component: () => import('@/views/auth/ResetPassword.vue'),
        meta: {
          layout: 'full',
        },
      }, {
        path: 'verify/:token',
        name: 'verify',
        component: () => import('@/views/auth/VerifyEmail.vue'),
        meta: {
          layout: 'full',
          auth: 'guest'
        },
      }],
    }, {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    }, {
      path: '*',
      redirect: 'error-404',
    },],
})

// Before each
router.beforeEach(async (to, from, next) => {
  console.log("Testing####");
  // global loading only appear when no user found
  if (!store.state.user.user) store.commit('app/SET_LOADING', true)
  const user = await Auth.fetch()
  switch (to.meta?.auth) {
    case 'user':
      if (user?.user_type == 'USER') return next()
      next({ name: 'login' })
      break
    case 'admin':
      if (user?.user_type === 'ADMIN') return next()
      next({ name: 'login' })
      break
    case 'guest':
      if (user?.user_type == 'ADMIN') return next({ name: 'home-admin' })
      if (user) return next({ name: 'home' })
      next()
      break
    default:
      next()
  }

})

// After Each Visit
router.afterEach(() => {
  store.commit('app/SET_LOADING', false)
})

export default router
