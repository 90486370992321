export default {
    state: {
        loggedIn: false,
        user: null,
    },
    getters: {
        isLoginWithProvider(state) {
            return state.user?.provider_id
        },
        isAdmin(state) {
            return state.user?.user_type === 'ADMIN'
        },
        isUser(state) {
            return state.user?.user_type === 'USER'
        },
        balance(state) {
            return Number(state.user?.credit).toFixed(2) || 0.00;
        },
        userGroup(state) {
            return state.user?.group
        },
    },
    mutations: {
        HANDLE_CREDIT(state, credit) {
            console.log('balance added: ', credit);
            state.user = { ...state.user, credit: +state.user.credit + credit }
        },
        SET_CREDIT(state, credit) {
            state.user = { ...state.user, credit: credit }
        },
        SET_USER_DATA(state, payload) {
            state.loggedIn = true;
            state.user = payload;
        },
        UPDATE_API_KEY(state, val) {
            if(!state.user) return;
            state.user.api_key = val
        },
        LOGOUT(state) {
            localStorage.removeItem('token')
            state.loggedIn = false;
            state.user = null;
        },
    },
}
